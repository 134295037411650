// @flow
import React from 'react';
import Layout from '../components/layout';
import Link from '../components/link';
import GlossaryLink from '../components/glossary-link';
import crs from '../data/canyon-rating-system-definitions';
import CanyonRatingCard from '../components/canyon-rating-card';
import { withFrontMatter } from '../components';
import type { FrontMatter } from '../utils/types';
import { canyonRating as quiz } from '../data/quiz';
import Quiz from '../components/quiz';
import route from '../routes';

class Canyoneering_CanyonRatingSystem extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <CanyonRatingCard rating="3AIII" editable={true} />
        <p>
          Many years ago <GlossaryLink>Colorado Plateau</GlossaryLink>{' '}
          canyoneers were trying to establish a standard rating system for
          technical canyons that would entail all the different conditions one
          would encounter in the various slot canyons. Rich Carlson, through the{' '}
          <GlossaryLink id="american-canyoneering-association">
            ACA
          </GlossaryLink>
          , created the ACA Canyon Rating System and it was quickly adopted by{' '}
          <GlossaryLink>beta providers</GlossaryLink> and the canyoneering
          community.
        </p>
        <p>
          The{' '}
          <Link to="http://www.canyoneering.net/docs/ratings.pdf">
            official documentation
          </Link>{' '}
          differs slightly from how beta providers have used it. Below is my
          understanding of the rating system based on these observations.
        </p>
        <h2>Technical Rating</h2>
        <ul>
          <li>
            <b>1</b> {crs.technical[1].description}
          </li>
          <li>
            <b>2</b> {crs.technical[2].description}
          </li>
          <li>
            <b>3</b> {crs.technical[3].description}
          </li>
          <li>
            <b>4</b> {crs.technical[4].description}
          </li>
        </ul>
        <h2>Water Rating</h2>
        <ul>
          <li>
            <b>A</b> {crs.water.A.description}
          </li>
          <li>
            <b>B</b> {crs.water.B.description}
          </li>
          <li>
            <b>C</b> {crs.water.C.description}
          </li>
        </ul>
        <h2>Time Rating</h2>
        <ul>
          <li>
            <b>I</b> {crs.time.I.description}
          </li>
          <li>
            <b>II</b> {crs.time.II.description}
          </li>
          <li>
            <b>III</b> {crs.time.III.description}
          </li>
          <li>
            <b>IV</b> {crs.time.IV.description}
          </li>
          <li>
            <b>V</b> {crs.time.V.description}
          </li>
          <li>
            <b>VI</b> {crs.time.VI.description}
          </li>
        </ul>
        <h2>Risk Rating</h2>
        <ul>
          <li>
            <b>R</b> {crs.risk.R.description}
          </li>
          <li>
            <b>X</b> {crs.risk.X.description} Canyoneers who regularly do X
            canyons split the rating into X and{' '}
            <GlossaryLink id="xx-risk-rating">XX</GlossaryLink> where X is
            deadly and XX is also deadly. But more deadly.
          </li>
          <li>
            <b>XX</b> {crs.risk.XX.description} There are currently 6 publicly
            known XX canyons:{' '}
            <Link to="http://ropewiki.com/Bad_Neighbor">Bad Neighbor</Link>,
            <Link to="http://ropewiki.com/Bishop_Canyon">Bishop</Link>,{' '}
            <Link to="http://ropewiki.com/Box_Elder_Canyon">SOS</Link>,{' '}
            <Link to="http://ropewiki.com/PINTAC">PINTAC</Link>,{' '}
            <Link to="http://ropewiki.com/Long_Branch">Long Branch</Link>, and{' '}
            <Link to="http://ropewiki.com/Big_Freaking_Silo_(BFS)">BFS</Link>.
          </li>
        </ul>
        <h2>Slot Designation</h2>
        <ul>
          <li>
            <b>S</b> {crs.slot.S.description} Beta providers don’t commonly use
            this. The letter <b>S</b> is appended to the canyon rating.
          </li>
        </ul>

        <h2>Canyon Rating Quiz</h2>
        <Quiz quiz={quiz} />

        <hr />
        <p>
          The Colorado Plateau and the ACA aren't the only organizations that
          have created canyon rating system. Check out some of{' '}
          <Link to={route.page_other_canyon_rating_systems}>the others</Link>{' '}
          that exist or have existed.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('canyon-rating-system')(
  Canyoneering_CanyonRatingSystem
);
